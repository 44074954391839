.viewer{
    background-color: #e4e4e4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
    width: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  
  span{
    margin-top: 3px;
  }
